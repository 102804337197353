<template>
<div>
  <form 
    method="post" 
    class="form" 
    ref="mainForm"
    @submit.prevent="submit"
  >
    <p class="title title--3">
      <span v-if="!innerClient.id">Ajouter un client</span>
      <span v-else>Mettre à jour les infos client</span>
    </p>
    
    <fieldset class="form__field">
      <label for="firstname" class="form__label">
        Prénom
      </label>
      <input type="text" id="firstname" placeholder="Prénom" class="form__input" v-model="innerClient.firstname">
    </fieldset>

    <fieldset class="form__field">
      <label for="lastname" class="form__label">
        Nom
      </label>
      <input type="text" id="lastname" placeholder="Nom" class="form__input" v-model="innerClient.lastname">
    </fieldset>

    <fieldset class="form__field">
      <label for="tel" class="form__label">
        Téléphone (format international +33...)
      </label>
      <input type="text" id="tel" placeholder="Téléphone" class="form__input" v-model="innerClient.tel">
    </fieldset>

    <fieldset class="form__field">
      <label for="email" class="form__label">
        E-mail
      </label>
      <input type="text" id="email" placeholder="E-mail" class="form__input" v-model="innerClient.email">
    </fieldset>
    
    <fieldset class="form__field">
      <label class="form__label">Adresse(s) pour les notifications</label>

      <ul class="form__button-container">
        <li v-for="(l, i) in innerClient.locations" :key="i">
          {{ l }}

          <button 
            class="btn btn--danger btn--small"
            type="button"
            @click="removeElement('locations', l)"
          >
            <i class="fa fa-trash"></i>
          </button>
        </li>
      </ul>
    
      <DataSelect
        label="Ajouter une adresse"
        :value="innerClient.locations"
        :options="availableLocations"
        @add="addElement('locations', $event)"
        @remove="removeElement('locations', $event)"
      />
    </fieldset>
    
    <button class="btn btn--primary">
      Sauvegarder
    </button>
  </form>

</div>
</template>

<script>
import { mapGetters } from 'vuex';
import { DataSelect } from '../Common';
import { cloneDeep } from 'lodash';

export default {
  components: { DataSelect },

  props: {
    client: {
      type: Object,
      default: () => {},
    },

    availableLocations: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      innerClient: {},
    };
  },

  computed: {
    ...mapGetters('app', ['baseurl']),
  },

  created() {
    this.innerClient = cloneDeep(this.client);
  },

  methods: {
    submit(e) {
      e.preventDefault();

      if (this.innerClient.id) return this.$emit('save', this.innerClient);

      this.$emit('create', this.innerClient);
    },

    addElement(key, elem) {
      if (!elem) return;

      if (!this.innerClient[key]) {
        this.$set(this.innerClient, key, []);
      }

      this.$nextTick(() => {
        this.innerClient[key].push(elem);
      });
    },

    removeElement(key, elem) {
      const index = this.innerClient[key].indexOf(elem);
      this.innerClient[key].splice(index, 1);
    },
  },
};
</script>