<template>
  <div class="page-wrapper">
    <div class="infobox">
      <div class="infobox__box">
        <div class="infobox__title">
          Clients
        </div>

        <div class="infobox__content">
          {{ clients.length }}
        </div>
      </div>
    </div>

    <div class="page-wrapper__actions">
      <p class="title title--2">Actions</p>
      <button class="btn btn--primary" @click="newClient">
        Ajouter un client
      </button>
    </div>

    <div class="page-wrapper__search">
      <p class="title title--2">Rechercher</p>
      <input type="text" v-model="name" placeholder="Par nom">
      <input type="text" v-model="tel" placeholder="Par numéro de tel">
      <input type="text" v-model="adresse" placeholder="Par adresse">
    </div>

    <div class="page-wrapper__body">
      <table class="table">
        <tr>
          <td><strong>Informations</strong></td>
          <td><strong>Provenance</strong></td>
          <td><strong>Création</strong></td>
          <td><strong>Créneaux choisis</strong></td>
          <td></td>
        </tr>

        <tr v-for="(client, index) in clients" :key="index">
          <td>
            <p>{{ client.firstname }} {{ client.lastname }}</p>
            <p>{{ client.email }}</p>
            <p>{{ client.tel }}</p>
          </td>

          <td>
            <p v-if="client.origin">
              {{ client.origin }}
            </p>
            <p v-else>
              Manuel
            </p>
          </td>
          
          <td>
            <p>
              <span>{{ dateFormat(client.createTimestamp) }}</span>
            </p>
          </td>

          <td>
            <ul>
              <li v-for="(location, idLoc) in client.locations" :key="idLoc">
                {{ filterAddress(location) }}
              </li>
            </ul>
          </td>

          <td>
            <button @click="edit(client)">Editer</button>
          </td>
        </tr>
      </table>
    </div>

    <Toaster />

    <Modal v-if="showModal" @close="close">
      <div slot="body">
        <FormClient 
          :client="client"
          :availableLocations="availableLocations"
          @create="addClient"
          @save="updateClient"
          @error="error"
        />
      </div>
    </Modal>
  </div>
</template>

<script>
import { Toaster, Modal } from '@/components/Utils';
import Clients from '@/firebase/clients-db';
import Events from '@/firebase/events-db';
import { mapGetters, mapActions } from 'vuex';
import FormClient from '@/components/Clients/FormClient';
import { arrActions } from '@/mixins';
import { dateHelper } from '@/misc/moment.js';

const clientsDb = new Clients();
const eventsDb = new Events();

export default {
  components: { Toaster, FormClient, Modal, },

  mixins: [ arrActions ],

  data() {
    return {
      client: {},
      showModal: false,
      name: '',
      tel: '',
      adresse: '',
      events: [],
    };
  },

  created() {
    this.syncClients();
    this.getEvents();
  },

  computed: {
    ...mapGetters({
      clientsStore: 'clients/getClients',
      clientsByLocation: 'clients/getClientByLocation',
    }),

    today() {
      let date = new Date().toLocaleString('fr-fr', { 
        month: '2-digit',
        day: '2-digit',
        year: 'numeric'
      }).split('/');

      return date[2].split(' ')[0] + date[1] + date[0];
    },

    clients: {
      get() {
        return this.clientsStore.filter(client => {
          if (
            ((!this.name.length || (this.name.length && client.firstname.toLowerCase().indexOf(this.name.toLowerCase()) > -1)) ||
            (!this.name.length || (this.name.length && client.lastname.toLowerCase().indexOf(this.name.toLowerCase()) > -1))) &&
            (!this.tel.length || (this.tel.length && client.tel.toLowerCase().indexOf(this.tel.toLowerCase()) > -1)) &&
            (!this.adresse.length || (this.adresse.length && client.locations.filter(el => el.toLowerCase().indexOf(this.adresse.toLowerCase()) > -1).length))
          ) {
            return client;
          }
        });
      }
    },

    availableLocations() {
      return [...new Set(this.events.map(e => e.location))];
    },
  },

  methods: {
    ...mapActions({
      setToaster: 'toaster/setToaster',
      syncClients: 'clients/setClients',
    }),

    // async syncClients() {
    //   try {
    //     const clients = await clientsDb.readAll();

    //     this.setClients(clients);
    //   } catch (error) {
    //     this.setToaster({
    //       message: error,
    //       type: 'error',
    //       time: Date.now(),
    //     });
    //   }
    // },

    async getEvents() {
      const d = this.today.replace(/[^ -~]/g,'');

      const days = await eventsDb.readAll([
        ['idDate', '>=', d],
      ]);

      this.events = days.map(({ events }) => events).flat();
    },

    newClient() {
      this.client = Object.assign({}, {
        name: '',
        tel: '',
        locations: [],
      });

      this.$nextTick(() => {
        this.showModal = true;
      });
    },

    async addClient(client) {
      try {
        await clientsDb.create(client);
  
        this.setToaster({
          message: `Le client a bien été ajouté`,
          type: 'message',
          time: Date.now(),
        });
      } catch (error) {
        this.setToaster({
          message: error,
          type: 'error',
          time: Date.now(),
        });
      }
    },


    async updateClient(client) {
      try {
        const index = this.findIndexInData(this.clients, 'id', client.id);
        await clientsDb.update(client);

        this.clients[index] = Object.assign({}, client);
  
        this.setToaster({
          message: `Les infos client ont bien été enregistrées`,
          type: 'message',
          time: Date.now(),
        });
      } catch (error) {
        this.setToaster({
          message: error,
          type: 'error',
          time: Date.now(),
        });
      }
    },

    edit(client) {
      this.client = {};

      this.client = Object.assign({}, client);

      this.$nextTick(() => {
        this.showModal = true;
      });
    },

    error(error) {
      this.setToaster({
        message: `Une erreur est survenue : ${error}`,
        type: 'error',
        time: Date.now(),
      });
    },

    close() {
      this.client = {};
      this.showModal = false;
    },

    filterAddress(address) {
      return address.split(',').filter(a => a !== ' France').join(',');
    },

    dateFormat(date) {
      return dateHelper(date).format('DD/MM/YY');
    }
  },
};
</script>
